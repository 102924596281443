"use client";
import axios from "axios";
import Castle from "@castleio/castle-js";
import { PublicConfig } from "@/shared/PublicConfig";
import { AnalyticsUtils } from "@/shared/utils/AnalyticsUtils";

const castle = Castle.configure({ pk: PublicConfig.CASTLE_IO_PUBLIC_API_KEY });
let clientIp: string | undefined = undefined;
const analyticsUtils = new AnalyticsUtils();

axios.interceptors.request.use(
    async function (config) {
        try {
            const isQuoteApiRequest = config.url?.includes("quoting/quotes");
            const hasDataPayload = config.data && typeof config.data === "object";
            // If this is a quote request, generate and add Castle request token to quote.extra
            if (isQuoteApiRequest && hasDataPayload) {
                const token = await castle.createRequestToken();
                // Fetch the client IP from your server

                if (!clientIp) {
                    const ipResponse = await axios.get("/api/info");
                    clientIp = ipResponse.data.ipAddress;
                }

                const existingParamsInQuote = config?.data?.extra?.queryParams;
                const existingEmailInQuote = config?.data?.email;

                const browserExtraData = analyticsUtils.constructExtra(existingParamsInQuote, existingEmailInQuote);

                config.data.extra = {
                    ...config.data.extra,
                    ipAddress: clientIp,
                    castleToken: token,
                    ...browserExtraData
                };
            }
        } catch (error) {
            console.log("Error in request interceptor:", error);
        }
        return config;
    },
    function (error) {
        // Handle request error
        return Promise.reject(error);
    }
);
